<template>
	<mw-dialog
		v-model="dialog"
		title="Convert to User"
		@submit="submit"
        submit-btn
		cancel-btn
	>
		<template v-slot:dialog-body>
			<v-simple-table v-if="submission">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Email</th>
                    </tr>
                </thead>
				<tr>
					<td>
						{{ submission.first_name }} {{ submission.last_name }}
					</td>				
					<td>
						{{ submission.email }}
					</td>				
				</tr>
			</v-simple-table>
				<v-alert text class="mt-5" :type="!user ? 'success' : 'info'">
                    <span v-if="!user">No matching user record found</span>
                    <div v-if="user">Matching <a target="blank" :href="`/users/${user.id}`">user</a> found</div>
                </v-alert>
            <v-checkbox v-model="includeEmail" label="Send email requesting incentive details"></v-checkbox>
		</template>
		<template v-slot:button>
			<v-btn @click="dialog = true" x-small color="primary">
				Create User
			</v-btn>
		</template>
	</mw-dialog>
</template>
<script>
import MwDialog from "@c/ui/MwDialog";

export default {
	name: "ConvertSubmissionToUser",
	props: {
		value: { type: String },
	},
	data: () => {
		return {
			loading: null,
			id: null,
			dialog: null,
            includeEmail: null
		};
	},
	components: {
		MwDialog,
	},
	computed: {
		submission() {
			return this.$store.state.incentiveSubmissions.data[this.value];
		},
		user() {
			return this.id ? this.$store.state.users.data[this.id] : null;
		},
	},
	watch: {
        submission(submission){
            if( submission.user ){
                this.id = submission.user;
            }
        },
		dialog(v) {
			const self = this;
			if (v & !self.matchedEmail) {
				self.loading = true;
				self.$store
					.dispatch("users/fetchByEmail", this.submission.email)
					.then((id) => {
						self.id = id;
						self.loading = false;
					});
			}
		},
	},
	methods: {
		async addUser(){
            const self = this;
            if( !self.id ){
                let doc = {
                    first_name: self.submission.first_name, 
                    last_name: self.submission.last_name, 
                    email: self.submission.email,
                    role: "participant" 
                }
                return await self.$store.dispatch("users/new", doc ).then( id => {
                    self.id = id;
                })
            }
            else{
                return;
            }
        },
        sendEmail(){
            const self = this;
            self.$store.dispatch("emails/sendOut", {
				email: "general_email_to_user",
				template: "dfe_rewards_request_email",
				values: {user: this.id, project: this.submission.project}
			}).then( () => {
                self.$store.dispatch("incentiveSubmissions/patch", {id: this.value, status: "contacted" });
            })
        },
        submit() {
            const self = this;
            self.addUser().then( () => {
                self.$store.dispatch("incentiveSubmissions/patch", {id: this.value, user: this.id, status: "processed" });
                if( self.includeEmail ){
                    self.sendEmail();
                }
            })
		},
	},
};
</script>
