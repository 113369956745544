<template>
	<v-sheet flat v-if="project">
		<h2>{{project.internal_name}}</h2>
		<v-card flat color="lightest" class="rounded-lg mb-4">
			<v-expansion-panels v-model="panel" flat class="transparent">
				<v-expansion-panel class="transparent">
					<v-expansion-panel-header>
						{{ panel == 0 ? "Hide filters" : "Show filters" }}
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-card-text>
							<filter-records v-model="filters"></filter-records>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn @click="submit" color="primary">Update</v-btn>
						</v-card-actions>
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>
		</v-card>
		<v-data-table :headers="headers" :items="items">
			<template v-slot:item.actions="{ item }">
				<convert-submission-to-user v-if="!item.user" v-model="item.id"></convert-submission-to-user>
			</template>
			<template v-slot:item.submitted_at="{ item }">
				{{ item.created_at.toDate().toLocaleDateString() }}
			</template>
			<template v-slot:footer.prepend>
				<export
					:rows="items"
					:columns="headers"
                    name="Incentive Submissions"
					:disabled="!items.length"
				></export>
			</template>
		</v-data-table>
	</v-sheet>
</template>

<script>
import FilterRecords from "@c/incentives/Filter.vue";
import Export from "@c/downloads/Export";
import ConvertSubmissionToUser from "@c/incentives/ConvertSubmissionToUser";

export default {
	name: "IncentiveSubmissions",
	props: {
		id: {type: String}
	},
	data: () => {
		return {
			panel: null,
			filters: {
				created_by: [], 
				date_start: null, 
				date_end: null,
				statuses: []
			},
		};
	},
	computed: {
		project() {
			return this.$store.state.projects.data[this.id]
		},
        users() {
            return this.$store.state.users.data
        },
		recordFilters(){
			let filters = {
				...this.filters, 
				projects: [this.id]
			};
			return filters;
		},
		records() {
		
			return this.$store.getters["incentiveSubmissions/getByFilters"](this.recordFilters);
		},
		headers() {
			return [
				{ value: "name", text: "Name" },
				{ value: "email", text: "Email" },
				{ value: "amount", text: "Amount" },
				{ value: "sow", text: "Reference" },
				{ value: "submitter", text: "Submitter" },
				{ value: "submitted_at", text: "Submitted At" },
				{ value: "status", text: "Status" },
				{ value: "actions", text: "" },
			];
		},
		items() {
            const self = this;
			return self.records.map( r => {
                r.name = r.first_name + " " + r.last_name;
                r.submitter = self.users[r.created_by] ? self.users[r.created_by].name : "";
                return r;
            })
		},
	},
	components: {
		FilterRecords,
        ConvertSubmissionToUser,
        Export
	},
	methods: {
		submit() {
			this.$store.dispatch(
				"incentiveSubmissions/fetchByFilters",
				this.recordFilters
			);
		},
	},
	created(){
		this.$store.dispatch("projects/fetchById", this.id)
		this.submit();
	}
};
</script>
